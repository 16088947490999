<template>
  <section class="qa part relative">
    <Image
      v-if="payload.bild?.id"
      class="hidden md:block image"
      :src="payload.bild"
    />
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-6 md:offset-1">
          <h2 class="title" v-html="payload.titel" />
          <h3 class="subtitle" v-html="payload.untertitel" />
          <accordion :items="payload.fragen">
            <template #header="{ item, expanded }">
              <div class="header" :class="{ expanded }">
                <h4 v-html="item.frage" />
                <chevron v-if="item.antwort?.trim().length" />
              </div>
            </template>
            <template #default="{ item }">
              <div class="content" v-html="item.antwort" />
            </template>
          </accordion>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Chevron from '@/assets/images/chevron.svg?inline';

export default {
  components: { Chevron },
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.qa {
  @apply text-dark-orange;

  &:first-of-type {
    @include responsive('padding-top', calc(var(--header-height) + #{px(30)}), px(123));
  }
  @include responsive('margin-bottom', px(60), px(150));
}

.title {
  @include responsive('margin-bottom', px(24), px(62));
}

.subtitle {
  @apply text-l;
  @apply text-dark-orange;
  @apply font-medium;

  line-height: px(32);

  @screen md {
    @apply text-xl;
    line-height: px(64);
  }

  @include responsive('margin-bottom', px(16), px(32));
}

.header {
  font-size: 800;

  position: relative;
  padding-right: 3rem !important;

  display: flex;
  justify-content: space-between;
  cursor: pointer;

  @apply text-base;
  line-height: px(20);

  @screen md {
    @apply text-l;
    line-height: px(32);
  }

  .dropdown:first-child & {
    border-top: 2px solid var(--color-dark-orange);
  }

  @include responsive('padding', px(13) 0, px(13) 0);

  svg {
    margin-left: 1rem;
    width: px(25);
    will-change: transform;
    transition: transform var(--speed-fast) ease;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.expanded svg {
    transform: rotate(180deg) translateY(50%);
  }
}

.qa :deep(.dropdown) {
  border-bottom: 2px solid var(--color-dark-orange);
}

.content {
  padding: px(40) 0;
  border-top: 2px solid var(--color-dark-orange);

  :deep(p:not(:last-child)) {
     margin-bottom: px(40);
  }
}

.image {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  min-height: 95vh;
  object-fit: cover;
  clip-path: ellipse(80% 70% at 110% 30%);
}
</style>
